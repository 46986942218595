import React from "react";
import OfferCreationForm from "./OfferCreationForm/OfferCreationForm";
function App() {
  return (
    <div className="d-flex">
      <OfferCreationForm />
    </div>
  );
}

export default App;
